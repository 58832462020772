import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@/layouts'
import Feature from '@/components/Feature'
import MediaBlock from '@/components/MediaBlock'
import MealDeliveryInformation from '@/components/MealDeliveryInformation'

type Props = {
  data: Object,
}

function MealDeliveryPage({
  data: {
    page: {
      data: {
        title,
        heroTitle,
        heroDescription: { html: heroDescription },
        heroPhoto,
        photos,
        menus,
        content,
      },
    },
  },
}: Props) {
  return (
    <Layout>
      <Feature
        wrapperBackground
        hero={true}
        title={heroTitle}
        titleAccent
        largeTitle
        description={
          <div dangerouslySetInnerHTML={{ __html: heroDescription }} />
        }
        mediaBlock={<MediaBlock src={heroPhoto.url} alt="" />}
      />
      <MealDeliveryInformation photos={photos} menus={menus}>
        <div dangerouslySetInnerHTML={{ __html: content.html }} />
      </MealDeliveryInformation>
    </Layout>
  )
}

export default MealDeliveryPage

export const query = graphql`
  {
    page: prismicMealDelivery {
      data {
        title
        heroTitle: hero_title
        heroPhoto: hero_photo {
          url
        }
        heroDescription: hero_description {
          html
        }
        content {
          html
        }
        menus {
          ... on PrismicMealDeliveryDataMenus {
            file: menu_file {
              url
            }
            title: menu_title
          }
        }
        photos {
          photo {
            url
          }
        }
      }
    }
  }
`