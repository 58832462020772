// @flow

import React from 'react'
import { css } from '@emotion/core'
import LeafPattern from '@/assets/leaf-pattern.png'
import LayoutContainer from '@/components/LayoutContainer'
import { LinkButton } from '@/components/Button'
import { mq } from '@/styles/theme'

type Props = {
  photos: Array,
  menus: Array,
  children: React.ReactNode,
}

const MealDeliveryInformation = ({ photos, menus, children }: Props) => (
  <div css={ContainerStyles}>
    <LayoutContainer>
      {photos && (
        <div css={PhotosStyles}>
          {photos.map(photo => (
            <img key={photo.photo.url} src={photo.photo.url} alt="" />
          ))}
        </div>
      )}
      <h2>Please see our monthly chilled and hot menus</h2>
      {menus && (
        <div css={MenusStyles}>
          {menus.map(menu => (
            <LinkButton key={menu.file.url} to={menu.file.url}>
              {menu.title}
            </LinkButton>
          ))}
        </div>
      )}
      {children}
    </LayoutContainer>
  </div>
)

export default MealDeliveryInformation

const ContainerStyles = css`
  background: var(--c-darkGreenBlue) url(${LeafPattern});
  padding: 85px 0;
  color: #fff;

  h2 {
    text-align: center;
  }
`

const MenusStyles = css`
  width: 100%;
  display: grid;
  gap: 30px;

  ${mq.greaterThan('small')`
    grid-template-columns: repeat(3, 1fr);
  `};
`

const PhotosStyles = css`
  width: 100%;
  display: none;
  gap: 30px;

  ${mq.greaterThan('small')`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  `};

  img {
    width: 100%;
    display: block;
  }
`
